import React from 'react';
import { Button } from '@zydalabs/zac-react';
import useTranslation from 'next-translate/useTranslation';

import { OrderModeButtonsWrapper } from '../style';

const Pickup: React.FC<{ onModeClicked?: (mode?: string) => void }> = ({ onModeClicked }) => {
  const { t } = useTranslation('branches');
  return (
    <OrderModeButtonsWrapper>
      <Button isFluid text={t('selectBranch')} onClick={() => onModeClicked()} />
    </OrderModeButtonsWrapper>
  );
};

export default Pickup;
