import { ShimmerComponent } from '@zydalabs/storefront-components';
import React from 'react';

const ModeLoading: React.FC = () => (
  <ShimmerComponent.ShimmerEffect>
    <ShimmerComponent.NoAnimationBox width="100%" height="50px" alignItems="center" p={2}>
      {Array.from([...Array(3)].keys()).map(num => (
        <ShimmerComponent.Rect key={`sh-order-mode-${num}`} width="30%" mx={2} height={20} />
      ))}
    </ShimmerComponent.NoAnimationBox>
  </ShimmerComponent.ShimmerEffect>
);

export default ModeLoading;
