import React, { useCallback, useEffect, useState } from 'react';
import { Tabs } from '@zydalabs/storefront-components';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import { useLayout } from 'service/hooks';
import { useGlobalDefaultOrderMode, useSelectAddressOrArea } from 'common/hooks';
import { changeRoute } from 'common/utils';
import { StyledModeWrapper } from './style';
import { FULFILLMENT_MODES, URLS, LANGUAGE_AR } from '../../../../common/constants';
import PickupMode from './PickupMode';
import DeliveryMode from './DeliveryMode';
import BeachMode from './BeachMode';
import ModeTapsLoading from './ModeTapsLoading';
import Delivery from './orderModeButtonsComponents/Delivery';
import Pickup from './orderModeButtonsComponents/Pickup';
import { useFulfillmentSettings } from '../../../../contexts';
import ServiceConfigs from '../../../../service/config';

const OrderMode: React.FC<{
  isActionSheet?: boolean;
  onModeClicked?: (mode: string) => void;
  onSelectAddressClick?: () => void;
  closeActionSheet?: () => void;
  isAccessDenied?: boolean;
}> = ({ isActionSheet, onModeClicked, closeActionSheet, onSelectAddressClick, isAccessDenied }) => {
  const router = useRouter();
  const { t } = useTranslation('layout');
  const [globalDefaultOrderMode] = useGlobalDefaultOrderMode();
  const { data, isLoading } = useLayout();
  const { orderMode: persistedOrderMode } = useFulfillmentSettings();
  const { enabledOrderModes } = data || { enabledOrderModes: {} };
  const isArabic = router.locale === LANGUAGE_AR;
  const [orderMode, setOrderMode] = useState<string>(persistedOrderMode || globalDefaultOrderMode);
  const isLoggedIn = ServiceConfigs?.getUserToken();

  const { handleSelectCurrentLocation, isLoading: isSelectingCurLocation } = useSelectAddressOrArea(null, closeActionSheet);

  const onOrderModeTapClick = useCallback(value => {
    if (!isActionSheet) changeRoute({ pathname: URLS.ORDER_MODE, query: { mode: value } });
    else setOrderMode(value);
  }, []);

  const onLocationClicked = useCallback(() => {
    if (isActionSheet) closeActionSheet();
    changeRoute({ pathname: URLS.ORDER_MODE, query: { mode: persistedOrderMode } });
  }, [persistedOrderMode]);

  const onTimeClicked = useCallback(() => {
    if (isActionSheet) closeActionSheet();
    changeRoute(URLS.ESTIMATED_TIME);
  }, []);

  const modesComponents = {
    [FULFILLMENT_MODES.DELIVERY]: (
      <Delivery
        onSelectCurLocationClick={handleSelectCurrentLocation}
        isLoading={isSelectingCurLocation}
        closeActionSheet={closeActionSheet}
      />
    ),
    [FULFILLMENT_MODES.PICKUP]: <Pickup onModeClicked={() => onModeClicked(FULFILLMENT_MODES.PICKUP)} />,
    [FULFILLMENT_MODES.CAR_PICKUP]: <Pickup onModeClicked={() => onModeClicked(FULFILLMENT_MODES.CAR_PICKUP)} />,
    [FULFILLMENT_MODES.BEACH]: <Pickup onModeClicked={() => onModeClicked(FULFILLMENT_MODES.BEACH)} />,
  };

  const availableOrderModes = Object.keys(enabledOrderModes)
    ?.filter(availableMode => enabledOrderModes[availableMode])
    .map(enabledMode => ({
      name: t(enabledMode),
      tabValue: enabledMode,
      ...(isActionSheet && persistedOrderMode !== orderMode && { component: modesComponents[enabledMode] }),
    }));

  useEffect(() => {
    setOrderMode(persistedOrderMode || globalDefaultOrderMode);
  }, [persistedOrderMode, globalDefaultOrderMode]);

  return (
    <>
      {isLoading ? (
        <ModeTapsLoading />
      ) : (
        <>
          <StyledModeWrapper isArabic={isArabic} isActionSheet={isActionSheet}>
            <Tabs tabs={availableOrderModes} value={orderMode} variant="underlined" showHeader onChange={onOrderModeTapClick} />
          </StyledModeWrapper>
        </>
      )}
      {persistedOrderMode === orderMode &&
        (persistedOrderMode === FULFILLMENT_MODES.PICKUP || persistedOrderMode === FULFILLMENT_MODES.CAR_PICKUP) && (
          <PickupMode onLocationClicked={onLocationClicked} onTimeClicked={onTimeClicked} />
        )}
      {persistedOrderMode === orderMode && persistedOrderMode === FULFILLMENT_MODES.DELIVERY && (
        <DeliveryMode
          onLocationClicked={isActionSheet && onSelectAddressClick && isLoggedIn ? onSelectAddressClick : onLocationClicked}
          onTimeClicked={onTimeClicked}
        />
      )}
      {persistedOrderMode === orderMode && persistedOrderMode === FULFILLMENT_MODES.BEACH && (
        <BeachMode onLocationClicked={onLocationClicked} onTimeClicked={onTimeClicked} />
      )}
    </>
  );
};

export default React.memo(OrderMode);
