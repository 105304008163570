import React from 'react';
import { ShimmerComponent } from '@zydalabs/storefront-components';

import { StyledListWrapper } from './style';

const ListLoading: React.FC = () => (
  <StyledListWrapper>
    {Array.from([...Array(6)].keys()).map(num => (
      <ShimmerComponent.ShimmerEffect key={`list-item-${num}`}>
        <ShimmerComponent.NoAnimationBox alignItems="center" py="12px" width={1} justifyContent="space-between">
          <ShimmerComponent.NoAnimationBox width="80%" flexDirection="column" height="fit-content">
            <ShimmerComponent.Rect height={16} width="50%" />
            <ShimmerComponent.Rect mt="8px" height={12} width="100%" />
          </ShimmerComponent.NoAnimationBox>
        </ShimmerComponent.NoAnimationBox>
      </ShimmerComponent.ShimmerEffect>
    ))}
  </StyledListWrapper>
);

export default ListLoading;
