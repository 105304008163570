import React from 'react';
import ServiceConfigs from 'service/config';
import UserBookList from 'modules/userModule/components/UserBookList';
import { OrderModeButtonsWrapper } from '../style';

const Delivery: React.FC<{
  onSelectCurLocationClick: () => void;
  isLoading: boolean;
  closeActionSheet?: () => void;
}> = ({ onSelectCurLocationClick, isLoading, closeActionSheet }) => {
  const isUserLoggedIn = !!ServiceConfigs.getUserToken();

  return (
    <OrderModeButtonsWrapper>
      {isUserLoggedIn && <UserBookList onItemClick={closeActionSheet} isDelivery />}
    </OrderModeButtonsWrapper>
  );
};

export default Delivery;
