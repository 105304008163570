import React, { ReactChild } from 'react';

const IcCar = (): ReactChild => (
  <svg
    id="Group_16382"
    data-name="Group 16382"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect id="Rectangle_6071" data-name="Rectangle 6071" width="24" height="24" fill="none" />
    <g id="car-2" transform="translate(2.705 -29.737)">
      <path
        id="Path_8619"
        data-name="Path 8619"
        d="M17.033,39.222l.289-.289a.63.63,0,0,0-.891-.891l-.3.3-.569-2.275A2,2,0,0,0,13.7,34.608H4.888a2,2,0,0,0-1.864,1.456l-.569,2.274-.3-.3a.63.63,0,0,0-.891.891l.289.289A2.776,2.776,0,0,0,0,41.713v2.213a2.769,2.769,0,0,0,1.083,2.2v1.1a1.64,1.64,0,0,0,1.638,1.638H3.954a1.64,1.64,0,0,0,1.638-1.638V46.7H13v.528a1.64,1.64,0,0,0,1.638,1.638h1.233a1.64,1.64,0,0,0,1.638-1.638v-1.1a2.769,2.769,0,0,0,1.083-2.2V41.713A2.776,2.776,0,0,0,17.033,39.222ZM3.652,38.745l.594-2.376a.737.737,0,0,1,.641-.5H13.7a.737.737,0,0,1,.641.5l.594,2.376a.132.132,0,0,1-.152.2H3.8a.188.188,0,0,1-.144-.045A.189.189,0,0,1,3.652,38.745ZM1.26,41.713A1.514,1.514,0,0,1,2.773,40.2H15.817a1.514,1.514,0,0,1,1.512,1.512v2.213a1.514,1.514,0,0,1-1.512,1.512H2.773A1.514,1.514,0,0,1,1.26,43.927Zm3.072,5.514a.383.383,0,0,1-.378.378H2.721a.383.383,0,0,1-.378-.378v-.561a2.782,2.782,0,0,0,.429.033h1.56v.528Zm11.914,0a.383.383,0,0,1-.378.378H14.635a.383.383,0,0,1-.378-.378V46.7h1.56a2.79,2.79,0,0,0,.429-.033v.561Z"
      />
      <path
        id="Path_8621"
        data-name="Path 8621"
        d="M36.321,141.859a1.713,1.713,0,1,0-1.713-1.713A1.715,1.715,0,0,0,36.321,141.859Zm0-2.166a.453.453,0,1,1-.453.453A.453.453,0,0,1,36.321,139.693Z"
        transform="translate(-32.442 -97.326)"
      />
      <path
        id="Path_8622"
        data-name="Path 8622"
        d="M209.361,141.859a1.713,1.713,0,1,0-1.713-1.713A1.715,1.715,0,0,0,209.361,141.859Zm0-2.166a.453.453,0,1,1-.453.453A.453.453,0,0,1,209.361,139.693Z"
        transform="translate(-194.651 -97.326)"
      />
    </g>
  </svg>
);

export default IcCar;
