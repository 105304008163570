import React, { useCallback } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useTheme, Icon } from '@zydalabs/storefront-components';
import { Button } from '@zydalabs/zac-react';
import { List } from 'common/components';
import { usePersistedUserCarId } from '../../../../common/hooks';
import ListItem from '../../../../common/components/ListItem';
import { useCarBook } from '../../../../service/hooks';
import { StyledLinkWrapper } from '../UserBookList/style';
import { IcCar } from '../../../../common/icons';
import { changeRoute } from '../../../../common/utils';
import { URLS } from '../../../../common/constants';

type CarListType = React.FC<{ onCarClick: any }>;

const CarList: CarListType = ({ onCarClick }) => {
  const theme = useTheme();
  const { t } = useTranslation('car');
  const [, setPersistedUserCarId] = usePersistedUserCarId();

  const { data, isLoading } = useCarBook();

  const handleOnCarClicked = useCallback(car => {
    setPersistedUserCarId(car.id);
    onCarClick();
  }, []);

  const renderItem = useCallback(
    car => (
      <ListItem
        dataTestId={`${car.id}_car_title`}
        title={car.make}
        details={car?.licenseNumber || ''}
        key={car.id}
        onClick={() => handleOnCarClicked(car)}
      />
    ),
    [],
  );

  return (
    <>
      <StyledLinkWrapper>
        <Button
          variant="ghost"
          text={t('addCar')}
          testId="add_new_car_btn"
          startIcon={<Icon icon={IcCar} color={theme.color.link} />}
          onClick={() => changeRoute(URLS.NEW_CAR)}
        />
      </StyledLinkWrapper>
      <List data={data} renderItem={renderItem} isLoading={isLoading} />
    </>
  );
};

export default CarList;
