import styled from 'styled-components';

export const StyledModeWrapper = styled.div`
  ${({ theme, isActionSheet }) => `
    margin: 0 ${theme.space[4]}px;
    ${!isActionSheet && `border-bottom: 1px solid ${theme.color.border};`}
  `}
`;

export const OrderModeButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[4]}px;
  margin-top: ${({ theme }) => theme.space[5]}px;
  margin-bottom: 10px;
`;
