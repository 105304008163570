import React, { ReactChild } from 'react';

const IcSchedule: () => ReactChild = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_16350" data-name="Group 16350" transform="translate(13134 9622)">
      <rect
        id="Rectangle_6073"
        data-name="Rectangle 6073"
        width="24"
        height="24"
        transform="translate(-13134 -9622)"
        fill="none"
      />
      <path
        id="Path_8597"
        data-name="Path 8597"
        d="M12,2.25A9.75,9.75,0,1,1,2.25,12,9.761,9.761,0,0,1,12,2.25Zm0,18A8.25,8.25,0,1,0,3.75,12,8.259,8.259,0,0,0,12,20.25Z"
        transform="translate(-13134 -9622)"
      />
      <path
        id="Path_8631"
        data-name="Path 8631"
        d="M15-7.25a.748.748,0,0,1-.53-.22l-3-3a.75.75,0,0,1-.22-.53v-4a.75.75,0,0,1,.75-.75.75.75,0,0,1,.75.75v3.689l2.78,2.78a.75.75,0,0,1,0,1.061A.748.748,0,0,1,15-7.25Z"
        transform="translate(-13134 -9599)"
      />
    </g>
  </svg>
);

export default IcSchedule;
