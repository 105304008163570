import React from 'react';
import { useRouter } from 'next/router';

import { InfoBlock } from 'common/components';
import { useShowAddress } from 'service/hooks';
import { useGlobalOrderTypeAndTime, usePersistedAreaTitle, usePersistedUserAddressId } from 'common/hooks';
import { LANGUAGE_AR } from 'common/constants';
import { getAddressDetails } from 'common/utils';
import { IcLocation, IcSchedule } from 'common/icons';
import useTranslation from 'next-translate/useTranslation';
import { ModeProps } from './types';
import { useFulfillmentSettings } from '../../../../contexts';

const DeliveryMode: React.FC<ModeProps> = ({ onLocationClicked, onTimeClicked }) => {
  const router = useRouter();
  const [areaTitle] = usePersistedAreaTitle();
  const { t, lang } = useTranslation('address');
  const [persistedAddressId] = usePersistedUserAddressId();
  const { data: addressDetails, isLoading: isAddressLoading } = useShowAddress({ addressId: persistedAddressId });
  const { area: areaDetails, isAreaLoading } = useFulfillmentSettings();

  const [globalOrderTypeAndTime] = useGlobalOrderTypeAndTime();
  const {
    estimatedTimeText,
    isLoading: isEstimateTimeLoading,
    fulfillmentTimeType,
    actionButtonText,
  } = globalOrderTypeAndTime || {};

  const isArabic = lang === LANGUAGE_AR;
  const estimatedTime = estimatedTimeText?.[lang] || '';

  const getDeliveryDetails = () => {
    if (persistedAddressId) return getAddressDetails(addressDetails, t, router.locale).fullAddress;
    if (areaDetails) return isArabic ? areaDetails.titleAr : areaDetails.titleEn;
    if (areaTitle) return areaTitle;
    return '';
  };

  const deliveryDetails = getDeliveryDetails();

  return (
    <>
      <InfoBlock
        isLoading={isAddressLoading || isAreaLoading}
        key="deliveryArea"
        title={persistedAddressId ? t('address') : t('layout:deliveringTo')}
        details={deliveryDetails}
        startIcon={IcLocation}
        onActionClick={onLocationClicked}
      />
      <InfoBlock
        key="deliveryTime"
        title={fulfillmentTimeType ? t('layout:arrivesIn') : t('layout:earliestAvailableTime')}
        isLoading={isEstimateTimeLoading}
        details={estimatedTime}
        startIcon={IcSchedule}
        onActionClick={onTimeClicked}
        actionText={actionButtonText}
      />
    </>
  );
};

export default React.memo(DeliveryMode);
