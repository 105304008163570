import styled from 'styled-components';

export const StyledListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.white};
`;

export const StyledListLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.white};
  margin: 0 ${({ theme }) => theme.space[4]}px;
`;
