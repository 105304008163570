import styled from 'styled-components';

export const StyledInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-inline-start: ${({ theme }) => theme.space[4]}px;
`;

export const StyledFirstIconWrapper = styled.div<{ hasTitle: boolean }>`
  align-self: ${({ hasTitle }) => (!hasTitle ? 'center' : 'flex-start')};
`;
