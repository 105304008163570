import React from 'react';
import { ShimmerComponent } from '@zydalabs/storefront-components';

const InfoBlockLoading = () => (
  <ShimmerComponent.ShimmerEffect>
    <ShimmerComponent.NoAnimationBox width="100%" height={71} py={3} px={4} justifyContent="space-between" alignItems="center">
      <ShimmerComponent.Circle radius="20" />
      <ShimmerComponent.NoAnimationBox width="100%" flexDirection="column" mx={4}>
        <ShimmerComponent.Rect width="30%" mb={2} height={10} />
        <ShimmerComponent.Rect width="25%" height={10} />
      </ShimmerComponent.NoAnimationBox>
      <ShimmerComponent.Rect width="10%" height={10} />
    </ShimmerComponent.NoAnimationBox>
  </ShimmerComponent.ShimmerEffect>
);

export default InfoBlockLoading;
