import React from 'react';

import { StyledListWrapper, StyledListLoadingWrapper } from './style';
import ListLoading from './loading';

type ListType = React.FC<{
  data: Array<any>;
  isLoading: boolean;
  renderItem: (item: any) => React.ReactChild;
}>;

const List: ListType = ({ data, isLoading, renderItem }) => {
  if (isLoading)
    return (
      <StyledListLoadingWrapper>
        <ListLoading />
      </StyledListLoadingWrapper>
    );

  return (
    <StyledListWrapper>
      {data.map(item => (
        <React.Fragment key={item.id}>{renderItem(item)}</React.Fragment>
      ))}
    </StyledListWrapper>
  );
};

export default List;
