import React from 'react';
import { Icon, useTheme } from '@zydalabs/storefront-components';
import { Typography } from '@zydalabs/zac-react';

import { StyledBlockWrapper } from 'common/styles';
import { IcChevronRight } from 'common/icons';
import InfoBlockLoading from './loading';
import { StyledFirstIconWrapper, StyledInfoContent } from './style';

type InfoBlockType = {
  isLoading?: boolean;
  startIcon?: any;
  endIcon?: any;
  isIconLarge?: boolean;
  isDetailsTextSmall?: boolean;
  actionText?: string;
  onActionClick?: () => void;
  title?: string;
  titleColor?: string;
  actionTextColor?: string;
  details?: string;
  errorText?: string;
  showEndIcon?: boolean;
  reverseEndIconOnRtl?: boolean;
};

const InfoBlock: React.FC<InfoBlockType> = ({
  isLoading = false,
  startIcon,
  endIcon = IcChevronRight,
  reverseEndIconOnRtl = true,
  actionText,
  showEndIcon = false,
  isIconLarge = false,
  isDetailsTextSmall = false,
  onActionClick,
  title,
  titleColor,
  actionTextColor,
  details,
  errorText,
}) => {
  const theme: any = useTheme();

  if (isLoading) return <InfoBlockLoading />;
  const isEndIcon = showEndIcon ? showEndIcon && !!onActionClick : !actionText && !!onActionClick;

  return (
    <StyledBlockWrapper onClick={onActionClick} isDisabled={!onActionClick}>
      {startIcon && (
        <StyledFirstIconWrapper hasTitle={!!title}>
          <Icon
            icon={startIcon}
            color={theme.color.primary}
            width={`${theme.space[isIconLarge ? 10 : 5]}px`}
            my={`${theme.space[2]}px`}
          />
        </StyledFirstIconWrapper>
      )}
      <StyledInfoContent>
        <Typography variant="body14" color={titleColor || theme.color.disabled} m={0} mb={4}>
          {title}
        </Typography>
        {details && (
          <Typography
            variant={isDetailsTextSmall ? 'heading14' : 'body16'}
            color={isDetailsTextSmall ? theme.color.disabled : theme.color.black}
            m={0}
          >
            {details}
          </Typography>
        )}
        {errorText && (
          <Typography variant="body16" color={theme.color.danger} mt={4}>
            {errorText}
          </Typography>
        )}
      </StyledInfoContent>
      {!!actionText && !!onActionClick && (
        <Typography variant="body14" color={actionTextColor || theme.color.primary}>
          {actionText}
        </Typography>
      )}
      {isEndIcon && (
        <Icon icon={endIcon} color={theme.color.primary} reverseOnRtl={reverseEndIconOnRtl} my={`${theme.space[2]}px`} />
      )}
    </StyledBlockWrapper>
  );
};

export default InfoBlock;
