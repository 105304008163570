import React from 'react';

import { InfoBlock } from 'common/components';
import { useGlobalOrderTypeAndTime } from 'common/hooks';
import { LANGUAGE_AR } from 'common/constants';
import { IcLocation, IcSchedule } from 'common/icons';
import useTranslation from 'next-translate/useTranslation';
import { ModeProps } from './types';
import { useFulfillmentSettings } from '../../../../contexts';

const PickupMode: React.FC<ModeProps> = ({ onLocationClicked, onTimeClicked }) => {
  const { t, lang } = useTranslation('layout');
  const { branch: branchDetails, isBranchLoading: isLoading } = useFulfillmentSettings();
  const [globalOrderTypeAndTime] = useGlobalOrderTypeAndTime();
  const {
    fulfillmentTimeType,
    actionButtonText,
    estimatedTimeText,
    isLoading: isEstimateTimeLoading,
  } = globalOrderTypeAndTime || {};

  const isArabic = lang === LANGUAGE_AR;

  return (
    <>
      <InfoBlock
        isLoading={isLoading}
        key="pickupBranchName"
        title={t('pickingFrom')}
        details={branchDetails && (isArabic ? branchDetails.titleAr : branchDetails.titleEn)}
        startIcon={IcLocation}
        onActionClick={onLocationClicked}
      />
      <InfoBlock
        isLoading={isEstimateTimeLoading}
        key="preparationTime"
        title={fulfillmentTimeType ? t('layout:readyWithin') : t('layout:earliestAvailableTime')}
        details={estimatedTimeText?.[lang]}
        startIcon={IcSchedule}
        onActionClick={onTimeClicked}
        actionText={actionButtonText}
      />
    </>
  );
};

export default React.memo(PickupMode);
