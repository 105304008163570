import React from 'react';

import { StyledUserBookListWrapper } from './style';
import CarList from '../CarList';
import AddressList from '../AddressList';

type UserBookListType = {
  isDelivery: boolean;
  onItemClick?: () => void;
  onItemClicked?: () => void;
  showAddLink?: boolean;
};

const UserBookList: React.FC<UserBookListType> = ({ isDelivery, onItemClick, onItemClicked, showAddLink = true }) => (
  <StyledUserBookListWrapper>
    {isDelivery ? (
      <AddressList showAddLink={showAddLink} onBeforeSelectAddress={onItemClick} onAfterSelectAddress={onItemClicked} />
    ) : (
      <CarList onCarClick={onItemClick} />
    )}
  </StyledUserBookListWrapper>
);

export default UserBookList;
